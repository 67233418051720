<template>
  <div>
    <UITabs class-button="block mb-4" class="flex w-full" :tabs="tabs" v-model="tab"/>

    <h1 class="text-2xl font-semibold text-gray-900">
      Blacklist {{ tabs[tab].label?.toLowerCase() }}
    </h1>
    <small class="-mt-1 mb-2 block">{{ information[tabs[tab].blacklist] }}</small>

    <KeepAlive>
      <BlacklistWhitelistTable :key="tabs[tab]" :blacklist="tabs[tab].blacklist" />
    </KeepAlive>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import UITabs from '@/components/UI/Tabs.vue'
import BlacklistWhitelistTable from '@/components/Table/BlacklistWhitelist.vue'

const tabs = [
  { label: 'Ritten', blacklist: 'RITTEN' },
  { label: 'Enquetes', blacklist: 'ENQUETES' },
  { label: 'Factuur op voorhand', blacklist: 'FACTUUR_OP_VOORHAND' },
]

const tab = ref(0)

const information = {
  RITTEN: 'Ritten van een blacklister zal een label BLACKLISTED krijgen in het dashboard.',
  ENQUETES: 'Er zullen geen enquetes meer gestuurd worden naar deze blacklist',
  FACTUUR_OP_VOORHAND: 'Facturen krijgen nu op voorhand standaard ook een mollie link, tenzij ze op deze blacklist staan',
}
</script>
